import React, { useEffect, useState } from 'react';
import {Paper,TableContainer,Table,TableCell, CardHeader ,LinearProgress,TableBody,TableHead,TableRow, AppBar,Modal, Toolbar,Button, Typography,List, Container, Grid2, TextField, Box, Link, ImageList, ListItem ,IconButton, Divider, ImageListItem, Icon, Card, CardContent, CardActions} from '@mui/material';
import {AddPhotoAlternate ,DeleteForever , CheckCircle,AddCircle,LocalShipping} from '@mui/icons-material';
import { ToastContainer, toast ,Bounce } from 'react-toastify';

import { useParams } from 'react-router-dom';
import LanguageSelector from './components/LanguageSelector';
import { addOrder } from './api';
import Notify from './components/Notify'
import {useTranslation} from 'react-i18next'
import { Helmet } from 'react-helmet';
import { ImageInfo } from './widgets/NewOrder';
import ImageUploader from './components/ImageUploader';
const SellerOrder= ()=>{
  const { t , i18n } = useTranslation();
  const [products ,setProducts]= useState([]);
  const [open ,setOpen] = useState(false);
  const [product , setProduct] = useState({});
  const [productImageUrl , setProductImageUrl] = useState();
  const [errors  , setErrors] = useState({});
  const [isSubmited , setIsSubmited] = useState(false);
  let { clientID } = useParams();

  const addProduct = ()=>{
    if(validateProduct()){
      setProducts([...products,product]);
      setProduct({});
      setProductImageUrl('');
      handleOpen();
    }
  
  }
  const handleOpen = ()=>{
  setOpen(!open);
  }
  useEffect(()=>{
    setProduct({...product,productImageUrl:productImageUrl})
  },[productImageUrl]);
   const resetProductsArea=()=>{
    setProducts([]);

   }
   const handleSendIt=async()=>{
    try {
      let res = await addOrder(clientID,products);

      Notify({message:res.data.message,type:res.data.status})      
      setIsSubmited(true);
    } catch (error) {
      Notify({message:error.message,type:'error'})      
    }
   }
   const handleProductChange =( e)=>{
    const { name, value } = e.target;
    let tmpErr = errors;
    delete tmpErr[name];
    setErrors(tmpErr);
    setProduct({ ...product, [name]: value });
   }
   const handleDeleteItem = (i)=>{
    let  tmpProducts = products;
    tmpProducts.splice(i,1);
    setProducts([...tmpProducts]);

   }
   const validateProduct=()=>{
    let tempErrors = {};
    let isValid = true;
  
    if (!product.productName) {
      tempErrors.productName = t("field is required");
      isValid = false;
    }
    if (!product.productImageUrl) {
      tempErrors.productImageUrl = t("product photo is required");
      isValid = false;
    }

    if (!product.quantity) {
      tempErrors.quantity = t("field is required");
      isValid = false;
    }
    if (!product.price) {
      tempErrors.price = t("field is required");
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
   }
    return <>
        <Helmet  htmlAttributes={{ dir:(i18n.language == 'ar')?"rtl":"ltr", lang:i18n.language}} />

      <AppBar position="static" className='bg-barsell'>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
          {t("New Order")}
          <LanguageSelector />
          </Typography>
        </Toolbar>
      </AppBar>
      <LinearProgress color='warning'/>

      <Container container sx={{ mt: 2 }}>
            <Typography variant='h5' className="text-center mt-4">{t('Add Product')}</Typography>
            <Card d variant="outlined">
             {!isSubmited &&
              <>
               <CardHeader 
              action={<IconButton><DeleteForever color='error' sx={{float:'inline-end  '}}
                onClick={resetProductsArea}></DeleteForever></IconButton>}
              title={t('products')}
              > 
             
              </CardHeader>
             </>}
              <CardContent className="card-body">
              {isSubmited && <Typography className='text-center'>
                 <CheckCircle sx={{display:'block',m:'auto',fontSize:100}}  color='success'/>
                  <>{t('Your Order Submited And Send it to the Customer , Thank You !')}</>
                </Typography>}
                {!isSubmited &&<>
                 <Table container className="products-area">

                  {products === undefined  || products.length === 0 && (
                    <TableRow className="intro_products_area">
                     <>{t('there is no Products untill now you can add it')}</>
                
                    </TableRow>
                  )}
                  {products.length > 0   && <>
                    <List sx={{ width: '100%', maxWidth: 450,m:"auto"}}>
     <ListItem sx={{borderRadius:"10px",px:"5px",m:1}}  key={"head_start"} >
             <Grid2 container size={12} > 
               <Grid2 size={4}>{"name"}</Grid2>
               <Grid2 size={4}>{"Quantity"}</Grid2>
               <Grid2 size={4}>{"Price"}</Grid2>

             </Grid2>
           </ListItem>
       {
        products.map((e,i)=>
          <> <ListItem sx={{bgcolor:'#ebeaea',borderRadius:"10px",px:"5px",m:1}}  key={i}
           disableGutters
           secondaryAction={
             <IconButton aria-label="comment">
               <DeleteForever onClick={(e)=>handleDeleteItem(i)} />
             </IconButton>  
           }>
             <Grid2 container size={12} > 
               <Grid2 size={4}>{e.productName}</Grid2>
               <Grid2 size={4}>{e.quantity}</Grid2>
               <Grid2 size={4}>{e.price}</Grid2>

             </Grid2>
           </ListItem>
           </>
           
         )
       }
       <ListItem sx={{borderRadius:"10px",px:"5px",m:1}}  key={"_start"} >
             <Grid2 container size={12} > 
               <Grid2 size={4}>{t("Total")}</Grid2>
               <Grid2 size={4}>{products.reduce((total, item) => total + Number(item.quantity), 0)}</Grid2>
               <Grid2 size={4}>{products.reduce((total, item) => total + Number(item.price), 0)}</Grid2>

             </Grid2>
           </ListItem>
     </List>
                  </>}
                  </Table>
                  </>
}
                  </CardContent>
                  {!isSubmited &&  <CardActions>
                    {products.length < 1 &&
                        <Button startIcon={<AddCircle/>} color='success' variant='contained' onClick={handleOpen}>{t('Add Product')}</Button>
                    }
                    {products.length > 0 &&
                    <>
                        <Button startIcon={<AddCircle/>} color='success' variant='contained' onClick={handleOpen}>{t('Add Product')}</Button>
                        <Button startIcon={<LocalShipping/>} color='warning' sx={{marginLeft:'auto !important'}} variant='outlined'  onClick={handleSendIt}>{t('Send It')}</Button>

                    </>

                    }
                  </CardActions>
}
                  </Card>

                  </Container>
                  <Modal
  open={open}
  onClose={handleOpen}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,}}>
    <Typography  id="modal-modal-title" variant="h6" component="h2">
    {t("Product Info")} 
    </Typography>
    <Grid2 item size={12}>
    <TextField label={t("Product Name")} name='productName'  
    variant='outlined' type='text' value={product.productName} 
    onChange={handleProductChange} fullWidth sx={{ mt:1}}
    error={!!errors.productName}
    helperText={errors.productName || ""}
    />
    </Grid2>
    <Grid2 item size={12}>

    <TextField label={t("HS Code")} name='hsCode'  
    variant='outlined' type='text' value={product.hsCode} 
    onChange={handleProductChange} fullWidth sx={{ mt:1}}
    error={!!errors.hsCode}
    helperText={errors.hsCode || ""}/>
        </Grid2>
        <Grid2 item size={12}>
    <TextField label={t("Product URL")} name='productUrl'  
    variant='outlined' type='text' value={product.productUrl} 
    onChange={handleProductChange} fullWidth sx={{ mt:1}}
    error={!!errors.urlCode}
    helperText={errors.urlCode || ""}/>
        </Grid2>
        <Grid2 container size={12}>
        <Grid2 item size={6}>

    <TextField label={t("Quantity")} name='quantity'  
    variant='outlined' type='number' value={product.quantity} 
    onChange={handleProductChange} fullWidth sx={{ mt:1}}
    error={!!errors.quantity}
    helperText={errors.quantity || ""}/>
            </Grid2>
            <Grid2 item size={6}>

    <TextField label={t("Unite Price")} name='price'  
    variant='outlined' type='number' value={product.price} 
    onChange={handleProductChange} fullWidth   sx={{mt:1}}
    error={!!errors.price}
    helperText={errors.price || ""}/>
            </Grid2>
            </Grid2>
          <Grid2 sx={{mt:1}}>
          <ImageInfo.Provider value={{productImageUrl , setProductImageUrl}} >
            <ImageUploader />
            {!!errors.productImageUrl &&
            <Typography align='center' color='error'>{errors.productImageUrl }</Typography>
            }
          </ImageInfo.Provider>
          </Grid2>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>

    <Grid2 container >
      <Grid2 item size={6}>
      <Button variant='contained' color='info' onClick={handleOpen} >{t("Cancel")}</Button>

      </Grid2>
      <Grid2 item size={6} sx={{display:"flex"}}>

     <Button variant='contained' color='warning' sx={{ml:'auto'}} onClick={addProduct}>{t("Add")}</Button>
     </Grid2>

     </Grid2>
    </Typography>
  </Box>
  
</Modal>
    </>;
}
export default SellerOrder;