import { useMemo,useRef, useEffect, useState, useContext } from "react";
import { faRedo, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, InputGroup, FormControl,Form } from 'react-bootstrap';
import UserContext from '../UserContext';
import { ToastContainer, toast ,Bounce } from 'react-toastify';
import { checkotp, phoneLogin } from "../api";
import Notify from '../components/Notify';
const countries = [
  {name:"🇴🇲",phoneCode:"+968",nameEn:"Oman"},
  {name:"🇦🇪",phoneCode:"+971",nameEn:"UAE"},
  {name:"🇸🇦",phoneCode:"+966",nameEn:"Saudi Arabia"},
  {name:"🇾🇪",phoneCode:"+967",nameEn:"Yemen"},


];

export const Login= ()=>{
  
  const [phone,setPhone] = useState('');
  const [title , setTitle] = useState('title');
  const [codeCountry, setCodeCountry] = useState('');
  const [isOtp, setIsOtp] = useState(false);


  const {isLogin, setIsLogin} = useContext(UserContext);   
 

  const [otp, setOtp] = useState(Array(4).fill(""));
  const [firstName, setfirstName] = useState(Array(4).fill(""));
  const [lastName , setLastName] = useState(Array(4).fill(""));
  const [email, setEmail] = useState(Array(4).fill(""));
  const [isActive ,setIsActive] = useState();
  const [timeLeft ,setTimeLeft] = useState();

  useEffect(()=>{
    console.log(isLogin+" Login From React");
    console.log(localStorage.getItem("isLogin")+" Login From LocalStorage");

    setPhone(localStorage.getItem("phone"));
    setCodeCountry((localStorage.getItem("codeCountry") == null)?"+968":localStorage.getItem("codeCountry"));
    setIsOtp((localStorage.getItem("isOtp")=="true"));
    setEmail((localStorage.getItem("email")))
    setfirstName((localStorage.getItem("firstName")))
    setLastName((localStorage.getItem("lastName")))

  }
    ,[isLogin])

  const [registerMode,setRegisterMode] =useState();

 
  useEffect(()=>{
    setTitle("تسجيل الدخول ");
  },[])
  const loginProcces =async (e)=>{
   
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
var raw = JSON.stringify({"phone":phone,"code":codeCountry});


if(registerMode){

   raw = JSON.stringify({"phone":phone,"code":codeCountry,"firstName":firstName,"lastName":lastName,"email":email});

}

try{
  let res = await phoneLogin(raw);
  console.log(res);

  Notify({message:res.data.message,type:res.data.status});
  if(res.data.code == "404"){
    setRegisterMode(true);
  }else{  
    setIsOtp(true);
    localStorage.setItem("isOtp",true);
    localStorage.setItem("codeCountry",codeCountry);
    localStorage.setItem("phone",phone);
  }
}catch(error){
  console.log(error);
  Notify({message:error.response.message || error.message,type:error.response.status || error.status});

}

  } 
  const inputs = useRef([]);

  const handleChange = (e, index) => {
      const { value } = e.target;
      if (/[^0-9]/.test(value)) return; // Allow only numeric input

      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < inputs.current.length - 1) {
          inputs.current[index + 1].focus();
      }
  };

  const handleKeyDown = (e, index) => {
      if (e.key === 'Backspace' && !otp[index] && index > 0) {
          inputs.current[index - 1].focus();
      }
  };

  const handlePaste = (e) => {
      const pasteData = e.clipboardData.getData('text').split('').filter(char => /\d/.test(char));
      if (pasteData.length === otp.length) {
          setOtp(pasteData);
          pasteData.forEach((value, index) => {
              inputs.current[index].value = value;
          });
      }
  };

  const handleResend = () => {
    loginProcces();
    setOtp(Array(4).fill(""));
      // Add your resend OTP logic here
      console.log('Resend OTP');
  };

  const handleLogin =async () => {
      // Add your login logic here
      var _otp= otp.join('');
      if(_otp.length < 4){
        Notify({message:"يجب ادخال الرمز كاملا",type:'warning'}) 
      }else{
          
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
const raw = JSON.stringify({"phone":phone,"code":codeCountry,"otp":otp.join('')});

try{
  let res = await checkotp(raw);
  var token = res.data.result.token;
  var userID = res.data.result.userId;
  Notify({message:res.message,type:res.status});
  localStorage.removeItem("isOtp");
  localStorage.removeItem("codeCountry");
  localStorage.removeItem("phone");
  localStorage.setItem("token",token);
  localStorage.setItem("isLogin",true);
  localStorage.setItem("userID",userID);

  setIsLogin(true);
  setRegisterMode(false);

}catch(error){
Notify({message:error.response.data.message || error.message,type:error.response.status || error.status});


}

  } 
      
  };
  useEffect(() => {
    let interval = null;

    if (isActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (!isActive && timeLeft !== 10) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, timeLeft]);
  const handleClick = () => {
    if (!isActive) {
      setIsActive(true);
    }
  };

    return (
        <>        
        
     <div className="mobile-containar" >
      
          <h3 className="text-center mt-4 mb-3">{title} </h3>
              <div className="tab-pane fade  show active " role="tabpanel" id="step1" aria-labelledby="step1-tab">
              { !(isOtp ) &&

                   <div className="mt-4">
          
          <InputGroup className="mb-2 m-auto w-max-content">
      <input
        type="number"
        required
        value={phone}
        className="d-block w-120px fs-1-4 form-control"
        onChange={(e) => setPhone(e.target.value)}
      />
      <select
        size="sm"
        style={{ minWidth: '100px' }}
        name="codeCountry"
        className="w-120px fs-1-4 form-select"
        value={codeCountry}
        required
        onChange={(e) => setCodeCountry(e.target.value)}
      >
        {countries.map((country) => (
          <option value={country.phoneCode} key={country.phoneCode}>
            {country.name + country.phoneCode}
          </option>
        ))}
      </select>
    </InputGroup>
           {registerMode && <>
           <div class="row">
            <div class="col-6">
            <InputGroup >
             <input type="text" placeholder="الأسم" required className="d-block fs-1-4  mr-auto form-control" style={{"max-width":"95%"}} onChange={(v)=>setfirstName(v.target.value)} />
              
            </InputGroup>
            </div>
            <div class="col-6">
            <InputGroup >
             <input type="text" placeholder="اللقب"  required className="d-block  fs-1-4  ml-auto form-control" style={{"max-width":"95%"}} onChange={(v)=>setLastName(v.target.value)} />
              
            </InputGroup>
            </div>
            </div>
            <InputGroup >
             <input type="email" placeholder="البريد الألكتروني"  required className="d-block fs-1-4   m-auto mt-2 form-control" style={{"max-width":"95%"}} onChange={(v)=>setEmail(v.target.value)} />
              
            </InputGroup>
           </>}
            <Button className="d-block m-auto fs-1-4 mt-4" variant="barsell" onClick={loginProcces}>
              دخول
            </Button>
          
            </div>
          }
           {isOtp && <div>
            
            <p className='text-center'>تم ارسال رمز التحقق عبر الواتساب</p>
                <p className='text-center'><span dir='ltr'>{localStorage.getItem("codeCountry")}{localStorage.getItem("phone")}</span><a className="link-offset-3" onClick={()=>{setIsOtp(false);localStorage.setItem("isOtp",false)}} style={{"margin-right": "7px",    "cursor": "pointer"}}>تعديل الرقم ؟</a></p>
        <div className="otp-container text-center position-relative">

            <InputGroup className="mb-3 otp-input-group" dir='ltr'>
                { otp.map((value, index) => (
                    <FormControl
                        key={index}
                        type="text"
                        value={value}
                        maxLength="1"
                        className="otp-input"
                        ref={el => inputs.current[index] = el}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={handlePaste}
                        autoFocus={index === 0} // Focus on the first input initially
                    >
                      
                        </FormControl>
                ))}
                
            </InputGroup>
            <div className="d-flex justify-content-between mt-3">
               
                
            </div>
            
        </div>
        <Button variant="link" className="resend-button d-block m-auto" onClick={handleResend}>
                    <FontAwesomeIcon icon={faRedo} />  إعادة إرسال
                </Button>
        <Button  variant="barsell" className="login-button d-block btn m-auto" onClick={handleLogin}>
                     دخول <FontAwesomeIcon icon={faSignInAlt} />
                </Button>
            </div>}

                
                  </div>
                </div>
        </>
    )
}

 