
// Test.jsx
import React, { useContext } from 'react';
import UserContext from './../UserContext';

const Test = () => {
  const context = useContext(UserContext);

  // Debugging: Check what the context contains
  console.log('Context:', context);

  // Ensure context is not undefined and has expected structure
  if (!context || typeof context.isLogin === 'undefined' || typeof context.setIsLogin !== 'function') {
    return <div>Context is not available or invalid</div>;
  }

  const { isLogin, setIsLogin } = context;

  return (
    <>
         <form action="http://5.9.215.12:3000/process-payment" method="POST">
        <label for="order_id">Order ID:</label>
        <input type="text" id="order_id" name="order_id" required/><br/><br/>
        
        <label for="amount">Amount:</label>
        <input type="text" id="amount" name="amount" required /><br/><br/>

        <label for="billing_name">Billing Name:</label>
        <input type="text" id="billing_name" name="billing_name" required /><br/><br/>
        
        <label for="billing_address">Billing Address:</label>
        <textarea id="billing_address" name="billing_address" required></textarea><br/><br/>
        
        <label for="billing_tel">Billing Tel:</label>
        <input type="text" id="billing_tel" name="billing_tel" required /><br/><br/>
        
        <label for="billing_email">Billing Email:</label>
        <input type="email" id="billing_email" name="billing_email" required /><br/><br/>
        
        <button type="submit">Proceed to Payment</button>
    </form>
    </>
  );
};

export default Test;