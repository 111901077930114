import React, { useEffect, useRef, useState } from 'react';
import {Button,ToggleButton, MenuItem ,Box,Card,ToggleButtonGroup,Container,styled,Paper , TextField,Toolbar,Typography,AppBar,ThemeProvider,createTheme } from '@mui/material';
import { orange  } from '@mui/material/colors';
import Select from'react-select';
import axios from 'axios';
import Grid from "@mui/material/Grid2";
import {getCategories,getPrices} from './api';

const theme =  createTheme({
    
    palette: {
        primary: {
          main: orange[500],
          contrastText: "#fff"
          
          
        },
        secondary: {
          main: "#ffffff",
          contrastText: orange[500],
        },
      },
    });
  const Unites = [
    {
      label:"cm",
      value :0
    },   
     {
      label:"m",
      value :1
    },
  ];
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));
const Calculator =()=>{
const [height, setHeight] = useState(0);
const [width, setWidth] = useState(0);
const [unitDim, setunitDim] = useState(0);
const [length, setLength] = useState(0);
const [weight, setWeight] = useState(0);
const [quantity, setQuantity] = useState(1);
const [_airType, set_AirType] = useState({_id:0,typeName:""});
const [_seaType, set_SeaType] = useState({_id:0,typeName:""});

const [omrSea,setOmrSea] = useState();
const [cbmSea,setCbmSea] = useState();
const [omrAir,setOmrAir] = useState();
const [cmAir,setCmAir] = useState();
const [isBrand , setIsBrand]= useState("no");
let seaNormalPrice= useRef(0);
let airNormalPrice= useRef(0);
let seaBrandPrice = useRef(0);
let seaPrices = useRef([]);
let seaType = useRef([]);

let airPrices = useRef([]);
let airType = useRef([]);

const [categories,setCategories] =useState([{label:"General",value:"N",_value:0}]);
const [mainCategories,setMainCategories] =useState([{label:"General",value:"N",_value:0}]);

const fetchCategories=async()=>{
  var r = await getCategories();
  setMainCategories(r.data);
  r =r.data.map(e => 
    e.description?.split(',').map((label,i) => ({ value: e._id+"_"+i, label ,_value:e._id}))
  ).flat().filter(Boolean);
  setCategories(r);

  }
const handleCategoryChange= (r)=>{
let _mC =mainCategories.filter(e=>e._id == r._value)[0];
console.log(_mC);
set_AirType(_mC.airType);
set_SeaType(_mC.seaType);

}
const fetchPrices=async ()=>{
 var pr = await getPrices();
 let  data =pr.data.data;
 seaNormalPrice.current = data.seaCase[0].additionalPrice;
 seaBrandPrice.current =data.seaCase[1].additionalPrice;
 seaPrices.current = data.seaPrice;
 airPrices.current = data.airPrice;
 airType.current = data.airType;
 seaType.current = data.seaType;

 }
useEffect(()=>{
  seaPriceGen();
  airPriceGen();

},[length,width,height,weight,unitDim,quantity,_airType,_seaType,isBrand])
const airPriceGen = ()=>{
let cmTmp  =((parseFloat(height)*parseFloat(width)*parseFloat(length)))*quantity;
if(unitDim == 1)cmTmp *=1000000;

let weightTmp = weight*quantity;
setCmAir((cmTmp/5000));
if((cmTmp/5000) > weight){
  weightTmp  = (cmTmp/5000) ;
}


let tmpAirType = (_airType._id != 0 )?_airType:airType.current[0];
let _price_air = airPrices.current.filter((e)=>{  

if(e.airType == tmpAirType._id){
  if(e.maxWeight >= weightTmp && e.minWeight <= weightTmp){   
    return true;} else if( e.maxWeight <= 0 && weightTmp >= e.minWeight){

      return true;}
  
} return false;

  
  })[0]?.price || 0;

  console.log("Price :"+_price_air);
  console.log("Weight :"+weightTmp);

  if(_price_air !=undefined)
    setOmrAir((_price_air*weightTmp).toFixed(2));
    else setOmrAir('--');
}
const seaPriceGen= ()=>{
  let additionalPrice = 0;
  let tmpSeaPrice =(isBrand == "yes")? seaNormalPrice.current + seaBrandPrice.current :seaNormalPrice.current;
  if(isBrand == "yes")additionalPrice += parseFloat(_seaType.brandPrice) || 0;
  else additionalPrice += parseFloat(_seaType.normalPrice) || 0;
  tmpSeaPrice +=additionalPrice;
  console.log(additionalPrice);
  console.log(_seaType);

  let seaCbmTmp = ((parseFloat(height)*parseFloat(width)*parseFloat(length)))*quantity;
  if(unitDim == 0)seaCbmTmp /=1000000;
setCbmSea(seaCbmTmp);
if((weight*quantity/400) > seaCbmTmp){
  seaCbmTmp  = (weight*quantity/400);
  
}
let _price_sea=tmpSeaPrice;
let tmp_price_sea = tmpSeaPrice;
console.log(_price_sea);
console.log("^^^");
 seaPrices.current.filter((e)=>{

  tmp_price_sea = Math.ceil(tmp_price_sea - ((tmp_price_sea/100)*e.price));
  if((e.maxWeight >= seaCbmTmp && e.minWeight <= seaCbmTmp )){   _price_sea =tmp_price_sea;}

  return true


});
console.log("^^^");

console.log(_price_sea);


if(_price_sea !=undefined)
setOmrSea((_price_sea)*((seaCbmTmp>1)?seaCbmTmp:1).toFixed(2));
else setOmrSea('--');
}
useEffect(()=>{
 fetchCategories();
 fetchPrices();
},[])
const handleBrandChange=(event , newValume)=>{
  setIsBrand(newValume);
  }
  
return <>
<div dir='ltr' style={{maxWidth:'450px',margin:"auto"}}>
<ThemeProvider theme={theme} >
<AppBar position="static" align="center">
  <Toolbar variant="dense" >

    <Typography variant="h6" align="center"  color="secondary" component="div">
    Shipping Calculator
    </Typography>
  </Toolbar>
</AppBar>

    <Container sx={{ m: 2 ,maxWidth:"450px",mx:"auto",mt:"50px" }} >
        <Box sx={{ flexGrow: 1 ,textAlign:"center"}}>
        <Grid container spacing={2} >
          <Grid size={6}>
        <TextField
          id="weight"
          label="Weight"
          size="small"
          min={0}
          value={weight}
          onChange={e=>{
            
            var value = parseFloat(e.target.value, 10);

            if (value < 0 || isNaN(value)) value = 0;
            setWeight(value)
            }}
          type="number"
          sx={{ml:3}}
        />
        </Grid>
        <Grid size={6} sx={{position:"relative"}}>
        <TextField
          id="quantity"
          label="quantity"
          size="small"
          min={1}

          value={quantity}
          onChange={e=>{
            var value = parseFloat(e.target.value, 10);

            if (value < 1 || isNaN(value)) value = 1;
            setQuantity(value)
            }}
          type="number"
          sx={{ml:3}}
        />
        
         </Grid>
                 <Grid size={3} sx={{position:"relative"}}>

        <TextField
          id="Length"
          size="small"
          label="Length"
          value={length}
          onChange={e=>{
            var value = parseFloat(e.target.value, 10);

            if (value < 0 || isNaN(value)) value = 0;
            setLength(value)}}
          type="number"
            sx={{ml:1}}
                />
          
                </Grid>
                <Grid size={3} sx={{ flexGrow: 1 ,textAlign:"center",position:"relative"}}>
          <TextField
          id="Height"
          size="small"
          label="Height"
          type="number"
          value={height}
          onChange={e=>{
            var value = parseFloat(e.target.value, 10);

            if (value < 0 || isNaN(value)) value = 0;
            setHeight(value)}}
            sx={{ml:1,width:"80%"}}
          />
       </Grid>
    
            
                 <Grid size={3} sx={{position:"relative"}}>
          <TextField
          value={width}
          id="Width"
          label="Width"
          size="small"
          onChange={e=>{  var value = parseFloat(e.target.value, 10);

            if (value < 0 || isNaN(value)) value = 0;
            setWidth(value)}}
          type="number"
          sx={{ml:1}}
     />
   
     </Grid>
     <Grid size={3}>
    <TextField
    labelId="demo-simple-select-label"
    value={unitDim}
    label="Unit"
   select
   size="small"
    onChange={e=>{setunitDim(e.target.value)}}
  >
  { Unites.map(e=> <MenuItem value={e.value}>{e.label}</MenuItem>)}
  </TextField>
    </Grid>
         <Grid size={12}>
     <Select  className='mt-2'  options={categories} onChange={(e)=>{handleCategoryChange(e)}} placeholder="Choose Matreial Of Product"></Select>
     </Grid>
     <ToggleButtonGroup value={isBrand} onChange={handleBrandChange} exclusive sx={{width:"100%"}}>
     <Grid size={6}>

          <ToggleButton sx={{width:"100%"}}  value="yes" aria-label="yes">
            <div>Brand</div> 
          </ToggleButton>
      
          </Grid>
          <Grid size={6}>

          <ToggleButton sx={{width:"100%"}}  value="no" aria-label="no" >
          <div>Not Brand</div> 

          </ToggleButton>
         
          </Grid>
          </ToggleButtonGroup>
          </Grid> 
          </Box>
       <Box sx={{ flexGrow: 1 ,textAlign:"center"}}>
       <Grid container spacing={2} sx={{backgroundColor:"#ccc",mt:2,p:2}}>
  <Grid size={6}>
    <h5 >Air </h5>
    <p>{_airType.typeName}</p>
    <p>VW : {cmAir} kg</p>

    <p>AW : {parseFloat(weight).toFixed(2)*quantity} Kg</p>
    <hr/>

    <p>{omrAir} OMR</p>


  </Grid>
  <Grid size={6}>
    <h5>Sea</h5>
    <p>{_seaType.typeName}</p>
    <p>{cbmSea } cbm</p>
    <p>{parseFloat(weight).toFixed(2)*quantity} Kg</p>
    <hr/>
    <h6>{omrSea } OMR</h6>
  </Grid>
  
</Grid>
<Grid size={6}>
Are You Ready ?<br></br>
  <a  className="btn btn-primary" href='/'>Send With Us </a>
</Grid>
</Box>
    </Container>
    </ThemeProvider>
</div>
</>

}
export default  Calculator;