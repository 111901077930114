import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, TextField, Button, Container, Card, CardContent, Grid2, LinearProgress } from '@mui/material';
import Notify from '../components/Notify';
import { searchOrder } from '../api';
import { useTranslation } from 'react-i18next';

const Receive = () => {
    const [orders, setOrders]= useState([]);
    const [searchTxt,setSearchTxt] = useState('');
    const [isSearhing,setIsSearching] = useState(false);
    const {t} = useTranslation();
    const fetchOrders= async()=>{
        setIsSearching(true);
        if(searchTxt.length >= 3){
         try{
            let res = await searchOrder(searchTxt);
   
            setOrders(res.data.data);
            console.log(orders);
         }catch(error){
            Notify({message:error.response.data.message || error.message,type:error.response.data.status ||error.status})
         }
        }else  Notify({message:"Must be at lest 3 Charcter"})
        setIsSearching(false);

    }
    const handleSearch =()=>{
        fetchOrders();
    }
  return (
    <div>
      {/* Top AppBar */}
      <AppBar position="static" sx={{ backgroundColor: '#FF6A00' }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
            Searching Order
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Search Section */}
      <Container sx={{ mt: 3 }}>
        <Grid2 container justifyContent="center">
          <Grid2 item size={12} sm={8} md={6}>
            <TextField
              fullWidth
              value={searchTxt}
              onChange={(e)=>{setSearchTxt(e.target.value)}}
              variant="outlined"
              placeholder="Product Name, Tracking Number, Client Code, Name"
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '30px',
                  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                },
              }}
            />
          </Grid2>
          <Grid2 item>
            <Button
              onClick={handleSearch}
              variant="contained"
              sx={{
                backgroundColor: '#FF6A00',
                color: 'white',
                borderRadius: '20px',
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                ml: 2,
                height: '100%',
                px: 4,
              }}
            >
              Search
            </Button>
          </Grid2>
        </Grid2>
      </Container>
        {isSearhing && <LinearProgress/>}
      {/* Results Section */}
      {!isSearhing && 
      <>
      <Container sx={{ mt: 4 }}>
        {orders.length > 0 &&
        <>
        {orders.map((_order, index) => (
          <Card key={index} sx={{ mb: 2 }}>
            
              <Button sx={{width:"100%"}}> 
              <CardContent>
              <Grid2 container alignItems="center">
                <Grid2 item size={3}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    #{_order._id}
                  </Typography>
                </Grid2>
                <Grid2 item size={6}>
                  <Typography variant="subtitle1" fontWeight="bold">
                   {_order.clientId.firstName} {_order.clientId.lastName}
                  </Typography>
                </Grid2>
                <Grid2 item size={3}>
                  <Typography variant="subtitle1" fontWeight="bold">
                   BAR-{_order.clientId.id}
                  </Typography>
                </Grid2>
                <Grid2 item size={12}>
                    {( Array.isArray(_order.Details))?_order.Details.map((_item)=>{
                            <Typography variant="body2" color="textSecondary">
                            {_item.productName}
                               </Typography>

                                        }):
                                    <Typography variant="body2" color="textSecondary">
                                        {t("Seller Not Add Any Product")}
                                    </Typography>}
                </Grid2>
              </Grid2>
              </CardContent>
              </Button>
           
          </Card>
        ))}
        </>
        }
        {orders.length < 1 &&
            <>
            <Typography align='center'>{t("No Data Found ")}</Typography>
            </>
        }
      </Container>
      </>
      }
    </div>
  );
};

export default Receive;
