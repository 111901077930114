
// Test.jsx
import React, { useContext } from 'react';
import UserContext from './../UserContext';

const Test = () => {
  const context = useContext(UserContext);

  // Debugging: Check what the context contains
  console.log('Context:', context);

  // Ensure context is not undefined and has expected structure
  if (!context || typeof context.isLogin === 'undefined' || typeof context.setIsLogin !== 'function') {
    return <div>Context is not available or invalid</div>;
  }

  const { isLogin, setIsLogin } = context;

  return (
    <div>
      {isLogin ? 'Logged In' : 'Logged Out'}
      <h1>Gu </h1>
      <button onClick={() => setIsLogin(!isLogin)}>
        Toggle Login
      </button>
    </div>
  );
};

export default Test;