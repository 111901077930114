import { Navbar ,Modal ,Button,Row,Col} from 'react-bootstrap';
import { ToastContainer, toast,Bounce } from 'react-toastify';
import { faAngleLeft,faAngleRight, faPencilSquare ,faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect ,useState ,useRef, createContext, useMemo } from 'react';
import Select from 'react-select'
import  {getCategories} from '../api';
import ImageUploader from '../components/ImageUploader';

export const ImageInfo = createContext();
export const OrderInfo = createContext();

export const NewOrder = ()=>{

    const [screen,setScreen] = useState('');
    const [subScreen , setSubScreen] = useState('');
    const [products ,  setProducts]= useState([]);
    const [productName , setProductName]= useState();
    const [hsCode , setHsCode]= useState();
    const [productUrl , setProductUrl]= useState();
    const [unitPices  , setUnitPices]= useState();
    const [unitPrice , setUnitPrice]= useState();
    const [productImage , setProductImage]= useState();
    const [errProductName , setErrProductName]= useState();
    const [errHsCode , setErrHsCode]= useState();
    const [errProductUrl , setErrProductUrl]= useState();
    const [errUnitPices  , setErrUnitPices]= useState(1);
    const [errUnitPrice , setErrUnitPrice]= useState();
    const [errProductImage , setErrProductImage]= useState();
    const [errImage, setImage] = useState(null);
    const [message, setMessage] = useState('');
    const [totalPrice,setTotalPrice]  = useState();
    const [totalPices,setTotalPices]  = useState();
    const [productImageUrl,setProductImageUrl]  = useState();
    const [errProductImageUrl,setErrProductImageUrl]  = useState();
    const [productMapKey,setProductMapKey]  = useState();
    const [orderID , setOrderId] = useState();
    const textareaRef = useRef(null); // Create a ref to access the textarea element

    // Function to copy text to clipboard
    const copyToClipboard = () => {
        if (textareaRef.current) {
            textareaRef.current.select(); // Select the text in the textarea
            document.execCommand('copy'); // Copy the text to the clipboard
            toast.info("تم نسخ النص", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              transition: Bounce,
              });  
        }
    };
const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);    
  
    useEffect(()=>{
        setScreen(localStorage.getItem('screen'));
        setSubScreen((localStorage.getItem('subScreen') == undefined)?'':localStorage.getItem('subScreen'));
        setProducts(JSON.parse(localStorage.getItem('products_order')));
        console.log( localStorage.getItem('products_order')      );
        fetchCategories();
    },[]);    
    useEffect(()=>{
  localStorage.setItem('screen',screen);


    },[screen]);
    const [categories, setCategories] = useState([]);
  
const fetchCategories= async()=>{
  var r = await getCategories();
  r =r.data.map(e => 
    e.description?.split(',').map(label => ({ value: e._id, label }))
  ).flat().filter(Boolean);
  setCategories(  r);
  
}

  
    useEffect(()=>{
     if(productName != "") setErrProductName("");
    },[productName])
    useEffect(()=>{
      if(unitPices != "") setErrUnitPices("");
     },[unitPices])
     useEffect(()=>{
      if(unitPrice != "") setErrUnitPrice("");
     },[unitPrice])
     useEffect(()=>{
      if(productUrl != "") setErrProductUrl("");
     },[productUrl])
    useEffect(()=>{
        localStorage.setItem('subScreen',subScreen);
          },[subScreen]);
          const handleAddProduct = () => {
          var allerr = 0;
            if(productName == undefined || productName.trim()== ""){
              setErrProductName("يجب ان تقوم بكتابة اسم المنتج")
              allerr++;
            }
            
            if(isNaN(unitPices)){
              setErrUnitPices("عدد القطع ضروري");
              allerr++;
            }
            if(isNaN(unitPrice) ){
              setErrUnitPrice("سعر المنتج ");
              allerr++;
            }
            if(productImageUrl == undefined || productImageUrl.trim()== ""  ){
              setErrProductImageUrl(" صورة المنتج ضرورية ");
              allerr++;
            }
            if(allerr == 0) {
              
            if(productMapKey != undefined ) {

              setProducts(products.map(product =>
                product.id === productMapKey
                    ? { ...product, 
                      "productName":productName,
                      "hsCode":hsCode,
                      "unitPrice":unitPrice,
                      "unitPices":unitPices,
                      "productUrl":productUrl,
                      "productImageUrl":productImageUrl}   : product
            ));
            }else{
              setProducts([...products, {
                id:products.length,
                productName,
                hsCode,
                unitPrice,
                unitPices,
                productUrl,
                productImageUrl
              }]);
            }
            resetElemnt();
        

              setShow();
              
            } 
          };
const resetElemnt=()=>{
  setProductName('');

  setProductName('');
  setHsCode('');
  setUnitPices('');
  setUnitPrice('');
  setProductUrl('');
  setProductImageUrl('');
}
  useEffect(()=>{
    console.log("Products Now is :"+products.length)

    setTotalPrice( products.map((_pr) => (_pr.unitPices * _pr.unitPrice))
    .reduce((prev, curr) => prev + curr, 0));
    setTotalPices( products.map((_pr) => (_pr.unitPices*1))
    .reduce((prev, curr) => prev + curr, 0));
  localStorage.setItem("products_order",JSON.stringify(products));
  },[products]);
  const editProduct = (e)=>{
    if(!isNaN(e)){
      console.log(products);
      setProductMapKey(e);
    console.log("Product Key :"+e);
    setProductName(products[e].productName);
    setHsCode(products[e].hsCode);
    setUnitPices(products[e].unitPices);
    setUnitPrice(products[e].unitPrice);
    setProductUrl(products[e].productUrl);
    setProductImageUrl(products[e].productImageUrl);
    setProductMapKey(e);
setShow(true);
    }
  }

  const dropProduct = (e)=>{
    if(!isNaN(e)){
      setProducts(products.filter(a => e!= a.id));
     
    }
  }
  const resetProductsArea=()=>{
    setProducts([]);
  }
  const upOrder=()=>{
    if(products.length < 1){
      toast.error("يجب أضافة منتج علاقل", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
        });  
    }else{
      const myHeaders = new Headers();
myHeaders.append("X-USER-TOKEN", localStorage.getItem('token'));
myHeaders.append("Content-Type", "application/json");


const raw = localStorage.getItem('products_order');

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

fetch("https://api.bar-sell.com/api/client/add-order", requestOptions)
  .then((response) => response.text())
  .then((r) =>{
      r = JSON.parse(r);
      if(r.success){
        toast.success(r.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
          }); 
           
          setOrderId(r.id);
          setSubScreen('orderDetails');
          resetElemnt();
          setProducts([]);
      }else{
        toast.error(r.error, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Bounce,
          }); 
      }
 
  })
  .catch((error) => console.error(error));

    }
  }
    return (
      <>
      {subScreen === "" && (
        
        <>
             <>
 <div className="">
                    <h3 className="d-block text-center mt-3">  لرفع  طلبك لدينا قم بأرسال رابط أضافة المنتجات الخاصة بك للبائع</h3>
                   <div className="copy_box">
                    <div className="text-wrap">
                        <textarea ref={textareaRef} disabled id="textA" style={{minHeight:"100px"}} className="w-100" dir="ltr" defaultValue={`尊敬的卖家，请点击以下链接添加产品详细信息。
https://app.bar-sell.com/${localStorage.userID}/new-order
然后通知我您已完成。
`}>
                        </textarea>

                        <div className="copy-button badge " onClick={copyToClipboard} id="copyToClipboard-a"><i className="fa fa-clipboard"></i>نسخ</div>
                        <div id="d-a" className="clipboard icon" onClick={copyToClipboard}></div>
                      </div>
                   </div>
                    
                   <p className="text-center">متشوقين لاستقبال شحنتك في مستودعاتنا   </p>

                
                </div>        
          </>
        </>
      )}

      {subScreen === "options" && (
        
        <>
          <div className="mt-4">
            <h2 className="text-center">معلومات الشحنة</h2>
            <div className="form-group">
              <label htmlFor="" className="m-1">المنتجات تم طلبها من :</label>
              <div className="btn-group w-100 m-1" data-toggle="buttons">
                <label className="btn btn-info">
                  <input type="radio" name="radio-vendor" id="radio-vendor1" autoComplete="off" checked /> بائع واحد
                </label>
                <label className="btn btn-info">
                  <input type="radio" name="radio-vendor" id="radio-vendor2" autoComplete="off" /> اكثر من بائع
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <a className="btn btn-barsell next m-auto mt-3" onClick={() => { 
              setSubScreen('addProduct'); 
              localStorage.setItem('subScreen', 'addProduct'); 
            }}>
              إضافة المنتجات <FontAwesomeIcon icon={faAngleLeft} />
            </a>
          </div>
        </>
      )}

      {subScreen === "addProduct" && (
        <>
          <div className="" aria-labelledby="step3-tab">
            <h2 className="text-center mt-4">أضافة المنتجات </h2>
            <div className="card mt-4 mb-3">
              <div className="card-header">المنتجات <FontAwesomeIcon icon={faTrash} className="text-danger float-end" onClick={resetProductsArea}></FontAwesomeIcon></div>
              <div className="card-body">
                <div className="products-area">
                  {products === undefined  || products.length === 0 && (
                    <div className="intro_products_area">
                      لم تقم بأضافة أي منتجات لشحنها حتى الأن بأمكانك البدء عبر الضغط بزر أضافة منتج ......
                
                    </div>
                  )}
                  
                  {(products != undefined  &&  products.length > 0) && (
                    <>
                      {products.map((_product, i) => (
                        <>
                        
                        <Row key={i}>
                        <Col xs="1"><FontAwesomeIcon   onClick={(i)=>{editProduct(_product.id);}} icon={faPencilSquare} className="text-barsell"></FontAwesomeIcon>
                        <br />
                        <FontAwesomeIcon onClick={()=>{dropProduct(_product.id)}} icon={faTrash} className="text-danger"></FontAwesomeIcon>
                        </Col>

                        <Col >
                        {_product.productName}
                        <br/>
                        {_product.unitPrice}x{_product.unitPices}

                        </Col>
                        <Col className='text-left'>
                        {_product.unitPrice * _product.unitPices} $
                        </Col>
                        </Row>
                        <hr />
                        </>

                      ))}
                      
                      <Row>
                        <Col>{totalPices} قطعة</Col>
                        <Col className='text-left'>${totalPrice} </Col>
                      </Row>
                      </>
                  )}
                </div>
                
                <hr />
                <button type="button" onClick={handleShow} className="btn btn-barsell" >
                  أضافة منتج
                </button>
              </div>
            </div>
                  <Modal show={show} onHide={handleClose} animation={false}>
                  <Modal.Header closeButton >بيانات المنتج</Modal.Header>                  
                  <Modal.Body>
                  <div className="mt-4 mb-2">
                      <input type="hidden" value={productMapKey} name="product-id" id="product-id" />
                      <div className="form-group">
                        <label htmlFor="product-name">اسم المنتج </label>
                        <input value={productName} onChange={(e) => setProductName(e.target.value)} type="text" name="product-name" id="product-name" className="form-control" placeholder="" aria-describedby="product-name" />
                        <small id="product-name" className="text-danger">{errProductName}</small>
                      </div>
                      <div className="form-group">
                        <label htmlFor="product-name">نوع المنتج </label>
                        <Select options={categories} />
                        <small id="product-name" className="text-danger">{errProductName}</small>
                      </div>
                     
                      <div className="form-group">
                        <label htmlFor="product-hs-code">HS Code <small className="badge bg-danger text-10px text-small text-truncate">قم بطلبه من البائع</small></label>
                        <input value={hsCode} onChange={(e) => setHsCode(e.target.value)} type="text" name="product-hs-code" id="product-hs-code" className="form-control" placeholder="" aria-describedby="product-hs-code" />
                      </div>
                      

                    
                      <div className="form-group">
                        <label htmlFor="product-url">رابط المنتج</label>
                        <input value={productUrl}  onChange={(e) => setProductUrl(e.target.value)} type="text" name="product-url" id="product-url" className="form-control" placeholder="" aria-describedby="product-url" />
                        <small id="product-url" className="text-danger">{errProductUrl}</small>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="product-quantity">عدد القطع</label>
                            <input value={unitPices} onChange={(e) => setUnitPices(e.target.value)} type="number" name="product-quantity" id="product-quantity" className="form-control" placeholder="" aria-describedby="product-hs-code" />
                             <small id="unitPices" className="text-danger">{errUnitPices}</small>

                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="product-unit-price">سعر القطعة</label>
                            <input value={unitPrice} onChange={(e) => setUnitPrice(e.target.value)} type="number" name="product-unit-price" id="product-unit-price" className="form-control" placeholder="" aria-describedby="product-hs-code" />
                            <small id="unitPrice" className="text-danger">{errUnitPrice}</small>

                          </div>
                        </div>
                      </div>
                      <div className="form-group mt-3 mb-2">
                        <label htmlFor="">صورة المنتج </label>
                        <ImageInfo.Provider  value={{productImageUrl , setProductImageUrl}}>
                        <ImageUploader />
                        </ImageInfo.Provider>
                        <input type="hidden" value={productImageUrl} />
                        <small id="productImageUrlErr" className="text-danger">{errProductImageUrl}</small>

                     
                      </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          إلغاء
          </Button>
          <Button variant="barsell" onClick={handleAddProduct}>
          إضافة
          </Button>
        </Modal.Footer>
                
          
              </Modal>

            <div className="d-flex justify-content-between">
              <a className="btn btn-secondary previous" onClick={() => setSubScreen('')}>
                <FontAwesomeIcon icon={faAngleRight} /> السابق
              </a>
              <a className="btn btn-barsell next" onClick={upOrder}>إتمام الطلب <FontAwesomeIcon icon={faAngleLeft} /></a>
            </div>
          </div>
        </>
      )}
        {subScreen === "orderDetails" && (
        <>
 <div className="">
                    <h3 className="d-block text-center mt-3">تم رفع طلبك</h3>
                    <h5><b>رقم الطلب : </b>#<span className='text-danger'>{orderID}</span></h5>
                    <p className="text-center">متشوقين لاستقبال شحنتك في مستودعاتنا   </p>
                   <div className="copy_box">
                    <div className="text-wrap">
                        <textarea ref={textareaRef} id="textA" className="w-100" dir="ltr" defaultValue={`广东省白云区凤鸣路120号a7仓(OM-BAR)
联系人: JACKSON : 13710722345
邮政编码: 510550
联系人: TAMEEM : 19066500477
邮政编码: 510550

防范措施：
1. 请务必询问他并在所有方框中写下此客户运输标记。否则不予受理。
2、请携带入库单复印件两份。入库前请提前与仓管员预约并告知发货时间。谢谢您的合作。
3.休息时间：周六下午2点至周日
4、因广州物流管控组通知，请积极配合仓管对货物进行拆箱检查并拍照上传。谢谢。
5、请积极配合仓管拆箱检查货物并上传照片。谢谢。
6、仓库工作及收货时间：周一至周六：9:00-19:30；周六 9:00-14:00
  节假日期间，请提前与销售人员预约并确认发货时间。
7、如果货物采用木托盘包装，请提前告知。
8、仓库不承担运费和货款。
9、严禁将任何易燃、易爆物品及中华人民共和国禁止出口的各类危险品带入仓库！`}>
                        </textarea>
                        <div className="copy-button badge " onClick={copyToClipboard} id="copyToClipboard-a"><i className="fa fa-clipboard"></i>نسخ</div>
                        <div id="d-a" className="clipboard icon" onClick={copyToClipboard}></div>
                      </div>
                   </div>
                    
                    
                    <div className="d-flex justify-content-between">
                        <a className="btn btn-barsell m-auto next">تتبع طلبك <i className="fa fa-truck"></i></a>
                        <a className="btn btn-barsell m-auto next" onClick={()=>{setSubScreen('')}}> شحنة جديد<i className="fa fa-truck"></i></a>

                    </div>
                </div>        
          </>
      )}
    </>
    )
}
