import axios from 'axios';
import Notify from './components/Notify';
const API_URL = 'https://api.bar-sell.com/api'; // Replace with your API URL
axios.defaults.headers.common['X-USER-TOKEN'] = localStorage.getItem('token');
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('x-user-token');
  
  if (token) {
    config.headers['X-USER-TOKEN'] = token;
  }
  return config;
  }, function (error) {
  return Promise.reject(error);
});
/********* Shipping Method ************/
export const apiUploadImage = (img) => axios.patch(`${API_URL}/client/upload-image`,img,{headers: {
    'Content-Type': 'multipart/form-data',
    'X-USER-TOKEN':localStorage.getItem('token')
  }});
export const getCategories = async () => await catchError(async ()=>{return await axios.get(`${API_URL}/client/category`)});
export const updateShippingMethod = async (id, data) => await catchError(async ()=>{return axios.put(`${API_URL}/client/shipping-methods/${id}`, data)});
export const addOrder = async (id, data) => await catchError(async ()=>{return await axios.put(`${API_URL}/frontend/${id}/add-order/`, data)});

export const deleteShippingMethod = async (id) => await catchError(async ()=>{return await axios.delete(`${API_URL}/client/shipping-methods/${id}`)});
export const getPrices = async () => await catchError(async ()=>{return await axios.get(`${API_URL}/frontend/prices`)});
export const getType = async () => await catchError(async ()=>{return await axios.get(`${API_URL}/frontend/get-type`)});

export const loginApi=async(data)=> await catchError(async ()=>{return await axios.post(`${API_URL}/login`, data)});
export const phoneLogin=async(data)=> await catchError(async ()=>{return await axios.post(`${API_URL}/phone-login`, data)});

export const checkotp=async(data)=> await catchError(async ()=>{return await axios.post(`${API_URL}/check-otp`, data)});

export const chkOrder = async (data) => await catchError(async ()=>{return await axios.post(`${API_URL}/frontend/chk-order`,data)});
export const upOrder = async (data) => await catchError(async ()=>{return await axios.post(`${API_URL}/frontend/up-order`,data)});
export const searchOrder = async (txt) => await catchError(async ()=>{return await axios.get(`${API_URL}/frontend/search-order?txt=${txt}`)});

const catchError = async (fn) => {
  try {
    
    let res =await fn();

    return res;
    console.log("End")

  } catch (error) {
    Notify({ message: error.message, type: 'error' });
    // Optionally, return something meaningful when an error occurs
  }
};