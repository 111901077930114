import React, { useEffect, useState } from 'react';
import {Paper,TableContainer,Table,TableCell,LinearProgress,TableBody,TableHead,TableRow, AppBar,Modal, Toolbar,Button, Typography,List, Container, Grid2, TextField, Box, Link, ImageList, ListItem ,IconButton, Divider, ImageListItem, Icon} from '@mui/material';
import {AddPhotoAlternate ,DeleteForever , Clear} from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast ,Bounce } from 'react-toastify';

import { useParams } from 'react-router-dom';
import LanguageSelector from './components/LanguageSelector';
import { apiUploadImage, chkOrder, upOrder } from './api';

const Seller= ()=>{
    let { orderId, tcorder } = useParams();
    const { t , i18n } = useTranslation();
    const [ isAllowed , setIsAllowed ] = useState(false);
    const [errors ,  setErrors] = useState({});
    const [ errorNot , setErrorNot]= useState();
    const [order,setOrder] = useState( { vendorName:"", vendorPhone:"", orderNo:0, clientNo:0,boxes:[{  boxId : 0, trackNo:"", boxDim :{ w:'', h:'', l:'' }, weight:'', photos:[], items :[ ] }] } );
    const [open , setOpen] = useState(false);
    const [boxItem , setBoxItem]=useState( { productName :"", quantity:'', price:'' });
   

  const checkOrder=async()=>{
      try{
        let res =  await chkOrder({
          "ordId":orderId,
          "ordTkn":tcorder
  
      });
      if(!res.data.data.Details)
      setIsAllowed(true);
      else setErrorNot(t("Already Subimted We Are Waiting To Receive it in Our Wearhouse"));
      
      }catch(err){
        toast.error(err.message);
      setIsAllowed(false);

      }
    }


    const handleBoxDimChange = (index, e) => {
      const { name, value } = e.target;
      const updatedBoxes = [...order.boxes];
      updatedBoxes[index].boxDim = {
        ...updatedBoxes[index].boxDim,
        [name]: value,
      };
      setOrder({ ...order, boxes: updatedBoxes });
    };
    const handleItemsChange = (index, e) => {
      const { name, value } = e.target;
      const updatedBoxes = [...order.boxes];
      updatedBoxes[index].items = {
        ...updatedBoxes[index].items,
        [name]: value,
      };
      setOrder({ ...order, boxes: updatedBoxes });
    };
    const handleOpen = ()=>{
      if(validateForm())
      setOpen(!open);
    }
    const handleBoxChange = (index, e) => {
      const { name, value } = e.target;
      const updatedBoxes = [...order.boxes];
      updatedBoxes[index] = { ...updatedBoxes[index], [name]: value };
      setOrder({ ...order, boxes: updatedBoxes });
    };
  
    const handleAddItem =(index)=>{
      const updatedBoxes = [...order.boxes];

      updatedBoxes[index].items = [
        ...updatedBoxes[index].items,
      { quantity:boxItem.quantity,
        productName:boxItem.productName,
        price:boxItem.price}
      ];
      setOrder({ ...order, boxes: updatedBoxes });

    }
    const handleDeleteItem =(index,_i)=>{
      const updatedBoxes = [...order.boxes];
      updatedBoxes[index].items.splice(_i,1)
      updatedBoxes[index].items = [
        ...updatedBoxes[index].items
      ];
      setOrder({ ...order, boxes: updatedBoxes });

    }
    const handleDeletePhoto =(e,index,_i)=>{
      const updatedBoxes = [...order.boxes];
      updatedBoxes[index].photos.splice(_i,1)
      updatedBoxes[index].photos = [
        ...updatedBoxes[index].photos
      ];
      setOrder({ ...order, boxes: updatedBoxes });

     }
     const handleAddBox = () => {
      const newBox = {
        boxId: order.boxes.length + 1, // Generate new boxId based on the current length
        trackNo: "",
        boxDim: {
          w: '',
          h: '',
          l: ''
        },
        weight: '',
        photos: [],
        items: []
      };
    
      setOrder({
        ...order,
        boxes: [...order.boxes, newBox]
      });
    };
    const handleRemoveBox = (index) => {
      const updatedBoxes = [...order.boxes]; // Copy the current boxes
      updatedBoxes.splice(index, 1); // Remove the box at the specified index
    
      setOrder({
        ...order,
        boxes: updatedBoxes // Update the order with the new boxes array
      });
    };
    const handleAddPhoto= async (e,index)=>{
      let file = e.target.files[0];
      

      if (file) {
          let reader = new FileReader();
          reader.onload =async (e) => {
              
              let formdata = new FormData();
              formdata.append("file", file, file.name);
              try{
                  var  response = await  apiUploadImage(formdata);
                  const updatedBoxes = [...order.boxes];

                  updatedBoxes[index].photos = [
                    ...updatedBoxes[index].photos,
                  "https://api.bar-sell.com/"+response.data.file.path
                  ];
                  setOrder({ ...order, boxes: updatedBoxes });
              }catch(err){
                  toast.error(err.message, {
                      position: "bottom-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                      transition: Bounce,
                      });
              }
         
              //setImageSrc(e.target.result);
          }
          reader.readAsDataURL(file);
      }
  };
  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;
  
    // Check vendorName and vendorPhone
    if (!order.vendorName.trim()) {
      tempErrors.vendorName = t("Vendor Name is required");
      tempErrors.vendorName = t("required");

      isValid = false;
    }
  
    if (!order.vendorPhone.trim()) {
      tempErrors.vendorPhone = t("Vendor Phone is required");
      tempErrors.vendorPhone = t("required");

      isValid = false;
    }
  
    // Check if all boxes are valid
    order.boxes.forEach((box, index) => {
      if (!box.trackNo.trim()) {
        tempErrors[`trackNo_${index}`] = t(`Tracking No for box `)+ (index + 1)+t(" is required");
        tempErrors[`trackNo_${index}`] = t("required");

        isValid = false;
      }
  
      if (!box.boxDim.w.trim()) {
        tempErrors[`boxDim_w_${index}`] = t("Width for box ")+(index + 1)+t(" is required");
        tempErrors[`boxDim_w_${index}`] = t("required");

        isValid = false;
      }
  
      if (!box.boxDim.h.trim()) {
        tempErrors[`boxDim_h_${index}`] = t("Height for box ")+(index + 1)+t(" is required");
        tempErrors[`boxDim_h_${index}`] = t("required");

        isValid = false;
      }
  
      if (!box.boxDim.l.trim()) {
        tempErrors[`boxDim_l_${index}`] = t("Length for box ")+(index + 1)+t(" is required");
        tempErrors[`boxDim_l_${index}`] = t("required");

        isValid = false;
      }
      if (box.items.length <= 0) {
        tempErrors[`items_${index}`] = t("At least add one product for box ")+(index + 1);
        tempErrors[`items_${index}`] = t("required");

        isValid = false;
      }
      if (!box.weight.trim()) {
        tempErrors[`weight_${index}`] = t("Weight for box ")+(index + 1)+t(" is required");
        tempErrors[`weight_${index}`] = t("required");

        isValid = false;
      }
    });
  
    setErrors(tempErrors);
    return isValid;
  };
  const sendOrder = async()=>{
    try {
      let res = await upOrder({ordId:orderId,ordTkn:tcorder,order});
      handleOpen()
      setIsAllowed(false);
      setErrorNot("Thank You , We Are Waiting To Your Package");
      
    } catch (error) {
      toast.error(error.response.data.message || error.message);

    }

  }
  useEffect(()=>{
    checkOrder();
     },[]);
    return <>
    <div dir={(i18n.language == 'ar')?"rtl":"ltr"} lang={i18n.language} >
      <AppBar position="static" className='bg-barsell'>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
          {t("Order Details")}
          <LanguageSelector />
          </Typography>
        </Toolbar>
      </AppBar>
      <LinearProgress color='warning'/>

      <Container sx={{ mt: 2 }}>
      {
        !isAllowed &&
      
        <Box sx={{ mt: 5, textAlign: 'center' }} >
          {errorNot || <LinearProgress/>}
      
        </Box>
      }
       {
        isAllowed &&
        <>
      
        <Box>
        <Grid2 container spacing={2}>
          <Grid2 item size={6}>
            Order No: #{orderId}
          </Grid2>
          <Grid2 item size={6} className="text-left" sx={{  fontFamily: 'monospace' }}>
            BAR-A-200
          </Grid2>
        </Grid2>
        <Box>
        <Grid2 container spacing={2}>
          <Grid2 item size={6}>
          <TextField variant="outlined"  
          value={order.vendorName } 
          error={!!(errors.vendorName || false)}
          helperText={errors.vendorName || ''}
          onChange={e=>setOrder({...order,vendorName:e.target.value})}
          name="vendorName"
          type="text"  fullWidth  
          label={t("Name")} />
            

          </Grid2>
          <Grid2 item size={6} className="text-left" sx={{  fontFamily: 'monospace' }}>
          <TextField variant="outlined" 
          value={order.vendorPhone} 
          error={!!errors.vendorPhone || false}
          helperText={errors.vendorPhone}
          onChange={e=>setOrder({...order,vendorPhone:e.target.value})}
          type="number"  fullWidth  
          label={t("Phone")} />

          </Grid2>
        </Grid2>
        </Box>
        <Divider/>
        { order.boxes?.map((e,i)=>{
          
          return <> <Box>
 <Grid2 container spacing={2} sx={{ mt: 2 }}>
 <Typography variant='h5' align='center' sx={{mt:2,borderBottom:"2px solid #ed6c0266"}}> Box {i+1 }</Typography>
 <IconButton sx={{    ml: "auto"}} onClick={e=>handleRemoveBox(i)} color='error' ><DeleteForever  /></IconButton>

 <Grid2 item size={12}>
     <span> {t("Box Dimension")} </span>
   </Grid2>
   <Grid2 item size={3}>
     <TextField variant="outlined" name="w" 
     value={e.boxDim?.w} onChange={e=>{handleBoxDimChange(i,e)}} 
     error={!!errors[`boxDim_w_${i}`] || false}
     helperText={errors[`boxDim_w_${i}`] || ''}
     type="number"  fullWidth  label={t("Width")} />
   </Grid2>
   <Grid2 item size={3}>
     <TextField variant="outlined" name="h" 
     value={e.boxDim?.h} onChange={e=>{handleBoxDimChange(i,e)}} 
     error={!!errors[`boxDim_h_${i}`] || false}
     helperText={errors[`boxDim_h_${i}`] || ''}
     type="number" fullWidth  label={t("Height")}/>
   </Grid2>
   <Grid2 item size={3}>
     <TextField variant="outlined"  name="l" 
     value={e.boxDim?.l} onChange={e=>{handleBoxDimChange(i,e)}}
     error={!!errors[`boxDim_l_${i}`] || false}
     helperText={errors[`boxDim_l_${i}`] || ''} 
     type="number" fullWidth label={t("Length")}/>
   </Grid2>
   <Grid2 item size={3}>
     <TextField variant="outlined"  name="weight" 
     value={e.weight} onChange={e=>{handleBoxChange(i, e)}} 
     error={!!errors[`weight_${i}`] || false}
     helperText={errors[`weight_${i}`] || ''} 
     type="number" fullWidth label={t("Weight")}/>
   </Grid2>
 </Grid2>
 <Grid2 container spacing={2} sx={{ mt: 2 ,mb:5}}>
 <Grid2 item size={12}>
     <span>{t("Photos of Box")} </span>
   </Grid2>
   <Grid2 container size={6}>

       {
         e.photos?.map((e,_i)=><>
              <Grid2 key={"photo_"+i} item size={4} className="imageSalerList">
                <ImageListItem > <img srcSet={`${e}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`} src={`${e}?w=164&h=164&fit=crop&auto=format`}  loading="lazy"/> 
                <IconButton className='clearIconImg' onClick={(e)=>{handleDeletePhoto(e,_i,i)}}>
                  <Clear />
                </IconButton>
                </ImageListItem>
              </Grid2>
         
         </>)
       }
       <Grid2 item size={4}>
       <input type='file' className='d-none'  id="addPhoto" onChange={(e)=>handleAddPhoto(e,i)} />
       <label htmlFor="addPhoto">
       <AddPhotoAlternate  sx={{fontSize:"60px"}} className='add-photo' />
       
       </label>
       </Grid2>
       
   </Grid2>
   <Grid2 item size={6}>
   <TextField variant="outlined" type="text" 
   name="trackNo" value={e.trackNo}  
   error={!!errors[`trackNo_${i}`]}
   helperText={errors[`trackNo_${i}`] || ''} 
   onChange={e=>handleBoxChange(i,e)} fullWidth label={t("Tracking NO")}/>

   </Grid2>
   <Divider/>
   <Grid2 item size={12}>
   <TextField variant="outlined" type="text"  value={boxItem.productName} 
   onChange={e=>setBoxItem({...boxItem,productName:e.target.value})}
   error={!!errors[`items_${i}`]}
   helperText={errors[`items_${i}`] || ''} 
   fullWidth label={t("Type Product Name ")}/>

   </Grid2>
   <Grid2 item size={4}>
   <TextField variant="outlined" type="number" value={boxItem.quantity} onChange={e=>setBoxItem({...boxItem,quantity:e.target.value})} fullWidth label={t("Quantity")}/>

   </Grid2>
   <Grid2 item size={4}>
   <TextField variant="outlined" type="number" value={boxItem.price} onChange={e=>setBoxItem({...boxItem,price:e.target.value})} fullWidth label={t("Unit Price")}/>

   </Grid2>
   <Grid2 item size={4} className="text-center">
   <span className='d-block'>{boxItem.quantity}x{boxItem.price}</span>
   <Button variant='outlined' color='primary'  onClick={e=>handleAddItem(i)}>{t("ADD")}</Button>
   </Grid2>
   <Grid2 size={12}>
     <List sx={{ width: '100%', maxWidth: 450,m:"auto"}}>
     <ListItem sx={{borderRadius:"10px",px:"5px",m:1}}  key={i+"_start"} >
             <Grid2 container size={12} > 
               <Grid2 size={4}>{"name"}</Grid2>
               <Grid2 size={4}>{"Quantity"}</Grid2>
               <Grid2 size={4}>{"Price"}</Grid2>

             </Grid2>
           </ListItem>
       {
         e.items?.map((e,_i)=>
          <> <ListItem sx={{bgcolor:'#ebeaea',borderRadius:"10px",px:"5px",m:1}}  key={i}
           disableGutters
           secondaryAction={
             <IconButton aria-label="comment">
               <DeleteForever onClick={(e)=>handleDeleteItem(i,_i)} />
             </IconButton>
           }>
             <Grid2 container size={12} > 
               <Grid2 size={4}>{e.productName}</Grid2>
               <Grid2 size={4}>{e.quantity}</Grid2>
               <Grid2 size={4}>{e.price}</Grid2>

             </Grid2>
           </ListItem>
           </>
           
         )
       }
       <ListItem sx={{borderRadius:"10px",px:"5px",m:1}}  key={"_start"} >
             <Grid2 container size={12} > 
               <Grid2 size={4}>{t("Total")}</Grid2>
               <Grid2 size={4}>{e.items?.reduce((total, item) => total + Number(item.quantity), 0)}</Grid2>
               <Grid2 size={4}>{e.items?.reduce((total, item) => total + Number(item.price), 0)}</Grid2>

             </Grid2>
           </ListItem>
     </List>
   </Grid2>
 </Grid2>
 </Box>
 </>
  })}
       
       
        </Box>
        <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
        display: 'flex',
        justifyContent: 'space-around', // Spread the buttons out evenly
        padding: '16px',
        zIndex: 1000, // Ensure it's above other elements
      }}
    >
      {/* Buttons */}
      <Button variant="contained" color="warning" onClick={handleAddBox}>
        {t("Add Box")}
      </Button> 
      <Button variant="contained" color="primary" onClick={handleOpen}>
        {t("Send")}
      </Button>
   
   
    </Box>
        </>
            }
      </Container>
      <Modal
  open={open}
  onClose={handleOpen}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,}}>
    <Typography  id="modal-modal-title" variant="h6" component="h2">
    {t("Packaging Info")} 
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    {order.boxes.map((bx,i) =>
   <Box key={"order_boxes_map_"+i}>

   <Typography>{i+1}-{t("Box")}  ({bx.boxDim.w}x{bx.boxDim.h}x{bx.boxDim.l}) - {t("Weight")+" "+bx.weight+"kg"}  </Typography>
  <TableContainer component={Paper}>
        <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("Product Name")}</TableCell>
            <TableCell align="right">{t("Quantity")}</TableCell>
            <TableCell align="right">{t("Price")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         
        {
            bx.items.map((row)=>
              <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.productName}
              </TableCell>
              <TableCell align="right">{row.price}</TableCell>
              <TableCell align="right">{row.quantity}</TableCell>
         
            </TableRow>
            )
           
}
        </TableBody>
      </Table>
    </TableContainer>
   
    </Box>
    )}
     <Typography variant='small' align='center' color='error'>*{t("By clicking Send you agree to our business policy, please make sure that the information above is correct, or you will be held responsible.")}</Typography>
     <Grid2 container >
      <Grid2 item size={6}>
      <Button variant='contained' color='info' onClick={handleOpen} >{t("Back")}</Button>

      </Grid2>
      <Grid2 item size={6} sx={{display:"flex"}}>

     <Button variant='contained' color='warning' sx={{ml:'auto'}} onClick={sendOrder}>{t("Send")}</Button>
     </Grid2>

     </Grid2>
    </Typography>
  </Box>
</Modal>
    </div>
    </>;
}
export default Seller;