import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Calculator from './Calculator';
import Seller from './Seller';
import { ThemeProvider  , createTheme} from '@mui/material';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import {Helmet} from "react-helmet";
import Wearhouse from './Wearhouse';
import Receive from './wearhouse/Receive';
import SellerOrder from './SellerOrder';

const theme = createTheme({

components:{
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        scrollbarColor:"#007 #bada55",
        scrollbarWidth: "thin",
        "scrollbar-colors":"#007 #bada55",
        '&::-webkit-scrollbar': {
          width: '10px', // Scrollbar width
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#ff9800', // Scrollbar thumb color
          borderRadius: '10px',
          border: '2px solid #ffffff', // Thumb border (optional)
        
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#eeeeee', // Scrollbar track color
        },
      },
    },
  },
  MuiTypography:{
    styleOverrides:{
      root: {
        '&.MuiTypography-h6': {
    
            fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
          
          // Ensuring the CSS specificity works for `dir="html"`
          '[dir="rtl"] &': {
            fontFamily: "'Cairo', Arial, sans-serif",
          },
        }
      }
    }
  },
  MuiTextField:{
    styleOverrides:{
      root: {
        '& .MuiInputBase-input': {
          fontFamily:`Roboto,Helvetica,Arial,sans-serif` ,
           '[dir="rtl"] &': {
            fontFamily: "'Tajawal', Arial, sans-serif",
          } 
        },
        '& .MuiFormLabel-root': {
          fontFamily:"tajawal,Arial,sans-serif", // Custom font family for the label
        },
    }

  },

}
}});
const router = createBrowserRouter([
  {
    path: "/",
    element: 
    <App />,
  },
  {
    path: "/calculator",
    element: 
    <ThemeProvider theme={theme}><Calculator /></ThemeProvider>
    ,
  },{
    path: "/:clientID/new-order",
    element:<ThemeProvider theme={theme}>
    <SellerOrder /></ThemeProvider> ,
  },{
    path: "/seller/:orderId/:tcorder",
    element:<ThemeProvider theme={theme}>
    <Seller /></ThemeProvider> ,
  },{
    path: "/wearhouse",
    element:<ThemeProvider theme={theme}>
    <Wearhouse /></ThemeProvider> ,
  },
  {
    path: "/wearhouse/receive",
    element:<ThemeProvider theme={theme}>
    <Receive /></ThemeProvider> ,
  }
]);
console.log(i18n.language);
console.log((i18n.language == 'ar')?"rtl":"ltr");


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

        <RouterProvider router={router} />
        <ToastContainer />
  </React.StrictMode>
);

reportWebVitals();
