import React, { useContext, useEffect, useState } from 'react';
import './ImageUploader.css'; // Import CSS for styling
import FormData  from 'form-data';
import { ImageInfo } from '../widgets/NewOrder';
import { apiUploadImage } from '../api';
import { ToastContainer, toast ,Bounce } from 'react-toastify';
const ImageUploader = () => {
    const [imageSrc, setImageSrc] = useState('https://api.bar-sell.com/uploads/upload-image.png'); // Default image
    const {productImageUrl , setProductImageUrl} = useContext(ImageInfo);
    useEffect(()=>{
        if(productImageUrl)
        setImageSrc(productImageUrl);
    },[]);
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload =async (e) => {
                
                const formdata = new FormData();
                formdata.append("file", event.target.files[0], event.target.files[0].name);
                try{
                    var  response = await  apiUploadImage(formdata);
                    console.log(response);
                    setProductImageUrl("https://api.bar-sell.com/"+response.data.file.path);
                }catch(err){
                    toast.error(err.response.data.message ||  err.message, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        transition: Bounce,
                        });
                }
           
                setImageSrc(e.target.result);
            }
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className="image-upload">
            <div className="image-edit">
                <input 
                    type="file" 
                    id="imageUpload" 
                    accept=".png, .jpg, .jpeg" 
                    onChange={handleFileChange}
                />
                <label htmlFor="imageUpload" className='fa fa-edit'> </label>
            </div>
            <div className="image-preview">
                <div 
                    className="image-preview-image" 
                    style={{ backgroundImage: `url(${imageSrc})` }}
                ></div>
            </div>
        </div>
    );
};

export default ImageUploader;